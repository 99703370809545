
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/567a73f84321482c/communities/communities-blog-ooi/src/components/CategoryMenu/Widget/index.tsx';

  import {
    withLeanStyles
  } from '@wix/native-components-infra';

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
var I18nextProvider = React.Fragment;


    var PureExperimentsProvider = React.Fragment;
    var ExperimentsProvider = React.Fragment;
    

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

var HttpProvider = React.Fragment;

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
}


  var stylesParams = null;

  var sentryConfig = {
      DSN: 'https://2062d0a4929b45348643784b5cb39c36@sentry.wixpress.com/1643',
      id: '2062d0a4929b45348643784b5cb39c36',
      projectName: 'communities-blog-ooi',
      teamName: 'blogas',
      
    };

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withLeanStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: 'a0d7808c-0d7d-4a40-8cf0-911a9f0de96f',
    name: 'CategoryMenu',
    withErrorBoundary: true,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
